import "./App.css";

import { useState, RefObject, createRef } from "react";
import { Auth } from "aws-amplify";

// Models
import { User } from "./models/User";

// Components
import Navbar from "./components/app/Navbar";
import Notifier from "./components/app/Notifier";

// Routing
import { lazy, Suspense } from "react";
import { Routes, Route } from "react-router-dom";

// Pages
const CreateRequest = lazy(() => import("./components/pages/CreateRequest"));
const NoMatch = lazy(() => import("./components/pages/NoMatch"));
const EcsLogs = lazy(() => import("./components/pages/EcsLogs"));

function App() {
  const [user, setUser] = useState<User | undefined>();

  //redirect to login page if user is not authenticated
  Auth.currentAuthenticatedUser()
    .then((cognitoUser) => {
      const payload = cognitoUser.signInUserSession.idToken.payload;
      setUser({
        username: payload["cognito:username"],
        name: payload["name"],
        guid: payload["custom:guid"],
        email: payload["email"],
      });
    })
    .catch((error) => {
      console.log("The user is not authenticated" + { error });
      Auth.federatedSignIn();
    });

  const notifierRef: RefObject<Notifier> = createRef();

  const toggleNotifier = (message: string, type: string) => {
    notifierRef.current?.toggle(message, type);
  };

  if (!user) {
    return <></>;
  }

  return (
    <>
      <Navbar user={user} />
      <Notifier ref={notifierRef} />

      <Suspense fallback={<div className="container">Loading...</div>}>
        <Routes>
          <Route
            path="/"
            element={
              <div className="container-fluid">
                <EcsLogs toggleNotifier={toggleNotifier} />
              </div>
            }
          />
          <Route
            path="/create"
            element={
              <div className="container">
                <CreateRequest user={user} toggleNotifier={toggleNotifier} />
              </div>
            }
          />
          <Route
            path="*"
            element={
              <div className="container">
                <NoMatch />
              </div>
            }
          />
        </Routes>
      </Suspense>
    </>
  );
}

export default App;
