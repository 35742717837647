// Libraries
import { Component } from "react";

// Models
import { User } from "../../models/User";

// type
type NavbarProps = {
  user: User;
};

export default class Navbar extends Component<NavbarProps> {
  override render() {
    return (
      <nav className="navbar navbar-expand-lg bg-dark" data-bs-theme="dark">
        <div className="container">
          <a className="navbar-brand" href="/">
            <img
              src="/assets/img/verimatrix-driving-trust-white-1024x208.png"
              alt="Verimatrix Driving Trust"
              width="200"
              height="41"
              className="d-inline-block align-text-top"
            />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarText"
            aria-controls="navbarText"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarText">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <a className="nav-link" href="/#/">
                  ECS Logs
                </a>
              </li>
            </ul>
            <span className="navbar-text">
              <b>{this.props.user.name}</b>
            </span>
          </div>
        </div>
      </nav>
    );
  }
}
