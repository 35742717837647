import { Component, MouseEvent } from "react";
import parse from "html-react-parser";

type NotifierProps = {
  ref: React.RefObject<Notifier>;
};

type NotifierState = {
  visible: boolean;
  message: string;
  type: string;
};

export default class Notifier extends Component<NotifierProps, NotifierState> {
  constructor(props: NotifierProps) {
    super(props);
    this.state = {
      visible: false,
      message: "",
      type: "success",
    };
  }

  toggle = (message: string = "", type: string = "success") => {
    let htmlMessage = "<span>" + message + "</span>";
    this.setState({ visible: true, message: htmlMessage, type: type }, () => {
      this.render();
    });
  };

  updateVisible = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    this.setState({ visible: false });
  };

  override render() {
    return (
      <>
        {this.state.visible ? (
          <div className="notifier">
            <div className="d-flex justify-content-center">
              <div
                className={`alert alert-${this.state.type} alert-dismissible fade show`}
                role="alert"
              >
                {parse(this.state.message)}
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="alert"
                  aria-label="Close"
                  onClick={this.updateVisible}
                ></button>
              </div>
            </div>
          </div>
        ) : null}
      </>
    );
  }
}
