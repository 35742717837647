import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";

// React Router
import { HashRouter } from "react-router-dom";

// Amplify
import { Amplify } from "aws-amplify";
import environment from "./settings/environment";
Amplify.configure(environment.Amplify);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <HashRouter>
    <App />
  </HashRouter>
);
