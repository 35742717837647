import { Auth } from "aws-amplify";

const environment = {
  production: true,
  Amplify: {
    Auth: {
      region: "eu-central-1",
      userPoolId: "eu-central-1_k3c1ZnkXH",
      userPoolWebClientId: "5ksu0s4cgqjfbqbms1oe12868f",
      oauth: {
        domain: "logging-dev.auth.eu-central-1.amazoncognito.com",
        scope: ["email", "openid", "profile"],
        redirectSignIn: "https://logging.infra.verimatrixdevcloud.io",
        redirectSignOut: "https://logging.infra.verimatrixdevcloud.io",
        responseType: "code",
      },
    },
    API: {
      endpoints: [
        {
          name: "BackendAPI",
          endpoint: "https://api.logging.infra.verimatrixdevcloud.io",
          custom_header: async () => {
            return {
              Authorization: (await Auth.currentSession())
                .getIdToken()
                .getJwtToken(),
            };
          },
        },
      ],
    },
  },
};

export default environment;
